<template>
  <div>
  <TosListVuetify serviceName="yard" entityName="handlings"  >
    <template v-slot:customFilters="slotProps">
      <v-container fluid>
       <v-row>
          <v-col cols="4">
            <v-text-field  :label="tLabel('Unit number')" v-model="slotProps.data.unitNumber" />
          </v-col>
          <v-col>
            <v-select :label="tLabel('Unit type')"  v-model="slotProps.data.unitType" :items="unitTypes" />
          </v-col>
       </v-row>
      </v-container>
    </template>
    <template v-slot:editF="slotProps">
       <v-container fluid>
        <v-row>
          <v-col cols="4">
            <v-text-field  :label="tLabel('Unit code')" v-model="slotProps.data.visitUnitCode" :error-messages="slotProps.errors.visitUnitCode"  />
          </v-col>
          <v-col>
            <v-select :label="tLabel('Handling type')"  v-model="slotProps.data.type" :items="handlingTypes" :error-messages="slotProps.errors.type"  />
          </v-col>
          <v-col>
            <v-select :label="tLabel('Transport type')"  v-model="slotProps.data.transportType" :items="transportTypes" :error-messages="slotProps.errors.transportType"  />
          </v-col>
          <v-col>
            <v-text-field  :label="tLabel('Voyage code')" v-model="slotProps.data.voyageCode" :error-messages="slotProps.errors.voyageCode"  />
          </v-col>
          <v-col>
            <v-select :label="tLabel('Handling status')"  v-model="slotProps.data.status" :items="handlingStatusTypes" :error-messages="slotProps.errors.status"  />
          </v-col>
       </v-row>
       </v-container>
    </template>
  </TosListVuetify>

</div>

</template>


<script>
import TosListVuetify from '../../../components/anagrafiche/TosListVuetify';
import FormatsMixins from "../../../mixins/FormatsMixins";

export default {
  name: "Units",
  data() {
    return {
      unitTypes: null,
      handlingTypes: ["IN","OUT","HK"],
      transportTypes: ["TRU","TRA","VES","INT"],
      handlingStatusTypes: ["P","A","E","C"],
    };
  },
  mounted(){
    this.loadCombo();
  },
  
  methods: {
    async prepareItem(data) {
    },
    async loadCombo() {
      this.unitTypes = await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "lookups/unitTypes");
    }    
  },
  components: {TosListVuetify},
  mixins: [FormatsMixins],
};
</script>
